import { IChartJSData } from "./IChartJSData"
import { IChartOptions } from "./ChartOptions"

const yBucketSizes = [0.001, 0.01, 0.1, 0.5, 1, 2, 5, 10, 20, 25, 50, 100, 200, 250, 500, 1000, 5000, 10000, 20000, 50000, 100000, 200000, 250000, 500000, 1000000, 5000000, 10000000]

export function placeWatermark(options: IChartOptions, data: IChartJSData,
  location: string = 'auto') {
  if (!options || !data) {
    return
  }

  if (location !== 'auto') {
    const parts = location.split('.')
    if (parts.length === 2) {
      options.watermark.alignY = parts[0]
      options.watermark.alignX = parts[1]
      return
    }
  }

  const wmw = options.watermark.width
  const wmh = options.watermark.height
  const chw = 820
  const chh = 465

  let yMinVal: number | null = null
  let yMaxVal: number | null = null
  let xMinVal: number | null = null
  let xMaxVal: number | null = null

  for (const ds of data.datasets) {
    if (ds.hidden) {
      continue
    }
    for (const dp of ds.data) {
      if (yMinVal === null || dp.y < yMinVal ) { yMinVal = dp.y }
      if (yMaxVal === null || dp.y > yMaxVal ) { yMaxVal = dp.y }
      if (xMinVal === null || dp.x < xMinVal ) { xMinVal = dp.x }
      if (xMaxVal === null || dp.x > xMaxVal ) { xMaxVal = dp.x }
    }
  }

  if (yMinVal === null || yMaxVal === null || xMinVal === null || xMaxVal === null) {
    return
  }

  let yStep = (yMaxVal - yMinVal) / 7
  for (const step of yBucketSizes) {
    if (yStep < step) {
      yStep = step
      break
    }
  }

  yMinVal = Math.floor(yMinVal / yStep) * yStep
  yMaxVal = (Math.floor(yMaxVal / yStep) * yStep) + yStep

  const xFactor = (xMaxVal - xMinVal) / chw
  const xWidth = wmw * xFactor * 1.1
  const yFactor = (yMaxVal - yMinVal) / chh
  const yWidth = wmh * yFactor * 1.1

  const tY = yMaxVal - yWidth
  const lX = xMinVal + xWidth
  const bY = yMinVal + yWidth
  const rX = xMaxVal - xWidth

  let tlHasCollision = false
  let trHasCollision = false
  let blHasCollision = false
  let brHasCollision = false

  for (const ds of data.datasets) {
    if (ds.hidden) {
      continue
    }
    for (const dp of ds.data) {
      if (tlHasCollision && trHasCollision && brHasCollision) {
        break
      }
      if (dp.y >= tY && dp.x <= lX ) {
        tlHasCollision = true
        continue
      }
      if (dp.y <= bY && dp.x <= lX ) {
        blHasCollision = true
        continue
      }
      if (dp.y >= tY && dp.x >= rX ) {
        tlHasCollision = true
        continue
      }
      if (dp.y <= bY && dp.x >= rX ) {
        brHasCollision = true
        continue
      }
    }
  }

  if ((!blHasCollision) || (tlHasCollision && trHasCollision && brHasCollision)) {
    options.watermark.alignX = 'left'
    options.watermark.alignY = 'bottom'
    return
  }

  if (!brHasCollision) {
    options.watermark.alignX = 'right'
    options.watermark.alignY = 'bottom'
    return
  }

  if (!tlHasCollision) {
    options.watermark.alignX = 'left'
    options.watermark.alignY = 'top'
    return
  }

  if (!trHasCollision) {
    options.watermark.alignX = 'right'
    options.watermark.alignY = 'top'
  }
}
