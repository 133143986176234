import { useCallback, useContext, useEffect, useMemo, useState } from "react"

import { PrefsContext } from "../../prefs/PrefsContext"

import { IQueryConfig, IChartDisplayConfig, IQueryConfigLookup } from "./IQueryConfig"
import { IChartJSData, IChartJSDataset, IChartJSDatasetLookup, IChartJSXYDataPoint } from "./IChartJSData"
import { BaseChartOptions, IChartOptions } from "./ChartOptions"
import {
  APIResult,
  APIResultLookup,
  DeviceInfo,
  LibraryChart,
  MetricLookup,
  NodeData,
  NodeDataLookup,
  SiteDataLookup
} from "../../api/types"
import { BasicLookup, NumberLookup } from "../../types/types"
import { defaultTimeScaleConfig, getTimeScaleConfig, TimeScaleConfig } from "./timescale"
import { convert, convertUnits } from '../../units/units'
import { generateDatasetsLabels } from "./labels"
import * as api from '../../api/endpoints'
import { DefaultColorManager } from "./LineColors"

import ChartView from "./ChartView"

interface ChartDataManagerProps {
  containerSource: string
  queryConfigs: IQueryConfig[]
  chartDisplayConfig: IChartDisplayConfig
  timeRange: string
  libraryChart?: LibraryChart
  libraryChartLoader?: any
}

interface siteDevices {
  [key: string]: DeviceInfo
}

const buildTimestampList = (timestamps: NumberLookup) => {
  const out = [] as number[]
  for (const ts in timestamps) {
    const its = parseInt(ts)
    if (its > 0) {
      out.push(its * 1000)
    }
  }
  out.sort()
  return out
}

export default function ChartDataManager(props: ChartDataManagerProps) {

  const { queryConfigs,
    chartDisplayConfig,
    timeRange
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [apiResults, setApiResults] = useState({} as APIResultLookup)
  const [metricNames, setMetricNames] = useState({} as MetricLookup)
  const [deviceLookup, setDeviceLookup] = useState<siteDevices>({} as siteDevices)
  const [quietReloadNeeded, setQuietReloadNeeded] = useState(false)

  const prefs = useContext(PrefsContext)
  const { units, customerAccess } = prefs

  // this reloads the chart periodically
  useEffect(() => {
    const interval = setInterval(() => {setQuietReloadNeeded(true)} , 300000)
    return () => clearInterval(interval)
  }, [setQuietReloadNeeded])

  // this loads the metric names once
  useEffect(() => {
    api.getAllMetricNames()
    .then((resp) => {
      const out = {} as MetricLookup
      for (const metric of resp) {
        out[metric.id] = metric
      }
      setMetricNames(out)
    })
  }, [])

  const borderDash = useCallback(() => {
    // for predictions: return [3, 2]
    return undefined
  }, [])

  const chartHasBars = useMemo(() => {
    for (const cfg of queryConfigs) {
      if (cfg.style === 'bar') {
        return true
      }
    }
    return false
  }, [queryConfigs])

  const chartHasMonthlyBuckets = useMemo(() => {
    for (const cfg of queryConfigs) {
      if (cfg.useBuckets && cfg.bucketSize === 2592000) {
        return true
      }
    }
    return false
  }, [queryConfigs])

  const areAllQueryConfigsValid = useMemo(() => {
    if (!queryConfigs || queryConfigs.length === 0) {
      return false
    }
    for (const cfg of queryConfigs) {
      if (
        (!cfg.metric) ||
        (cfg.source === 'gt-historical' && !cfg.site)
      ) {
        return false
      }
    }
    return true
  }, [queryConfigs])

  const queryConfigsLkp = useMemo(() => {
    const out = {} as IQueryConfigLookup
    for (const qc of queryConfigs) {
      out[qc.id] = qc
    }
    return out
  }, [queryConfigs])

  const chartData = useMemo(() => {
    if (!apiResults || Object.keys(apiResults).length === 0) {
      return
    }

    const datasets = {} as IChartJSDatasetLookup
    const dslist = [] as IChartJSDataset[]
    let prefUnits = units ? units : 'metric'
    const timestamps = {} as NumberLookup

    const labelMetdata = [] as BasicLookup[]

    const colorManager = DefaultColorManager()

    let dsOrder = 1
    let y1AxisTitle = ''
    let y2AxisTitle = ''

    for (const k in apiResults) {
      if (!apiResults[k] || !apiResults[k].nodes) {
        continue
      }

      const qc = queryConfigsLkp[k] || {} as IQueryConfig
      if (!qc.axis) { qc.axis = 'y'}
      if (!qc.style) { qc.style = 'line'}

      const sites = apiResults[k].sites || {} as SiteDataLookup
      const siteID = (apiResults[k].request && apiResults[k].request?.siteId) || ''
      const site = siteID in sites ? sites[siteID] : undefined
      if (prefUnits === 'site' && site && customerAccess &&
        customerAccess.sites && site.id in customerAccess.sites) {
        if (customerAccess.sites[site.id].units &&
          ['metric', 'imperial'].includes(customerAccess.sites[site.id].units)) {
            prefUnits = customerAccess.sites[site.id].units
        } else {
          prefUnits = 'metric'
        }
      }

      const labelTokens = {
        site: site?.name,
        position: 'None',
        source: apiResults[k].source || 'None'
      } as BasicLookup

      let ds = null as IChartJSDataset | null
      const nodes = apiResults[k].nodes || {} as NodeDataLookup
      for (const nk in nodes) {
        const node = nk in nodes ? nodes[nk] : {} as NodeData
        const nodeLabelTokens = { ...labelTokens }
        nodeLabelTokens['node'] = node.name || 'None'
        for (const sample of node.samples || []) {
          timestamps[sample.ts] = null
          const metrics = [] as string[]
          for (const key in sample.d) {
            if (key in metricNames) {
              metrics.push(key)
            }
          }
          if (metrics.length === 0) {
            continue
          }
          const sampleLabelTokens = { ...nodeLabelTokens }
          sampleLabelTokens['i'] = dsOrder.toString()
          sampleLabelTokens['deviceEui'] = sample.dev
          if (sample.dev in deviceLookup) {
            if (deviceLookup[sample.dev].position) {
              sampleLabelTokens['position'] = deviceLookup[sample.dev].position
            }
            if (deviceLookup[sample.dev].deviceType) {
              sampleLabelTokens['sensorType'] = deviceLookup[sample.dev].deviceType
            }
          }
          const metric = metrics.join(',')
          const dsKey = `${node.id}.${sample.dev}.${metric}` // TODO: make this more unique?

          let units = ''
          let val = sample.d[metrics[0]]
          if (metric in metricNames) {
            const m = metricNames[metric]
            if (prefUnits !== 'metric') {
              units = convertUnits(m.units || '', prefUnits)
              val = convert(sample.d[metrics[0]], m.units || '', prefUnits)
            } else {
              units = m.units || ''
            }
            sampleLabelTokens['metric'] = m.displayName
            if (qc.axis === 'y' && !y1AxisTitle) {
              if (!units) {
                y1AxisTitle = m.displayName
              } else {
                y1AxisTitle = m.displayName + ' - ' + units
              }
            }
            if (qc.axis === 'y2' && !y2AxisTitle) {
              if (!units) {
                y2AxisTitle = m.displayName
              } else {
                y2AxisTitle = m.displayName + ' - ' + units
              }
            }
          }
          sampleLabelTokens['units'] = units
          if (!(dsKey in datasets)) {
            labelMetdata.push(sampleLabelTokens)
            ds = {
              borderWidth: qc.style === 'bar' ? undefined : 2,
              pointRadius: 0,
              tension: 0.2,
              order: dsOrder,
              borderDash: borderDash(),
              borderRadius: 3,
              type: qc.style,
              fill: false,
              data: [] as IChartJSXYDataPoint[],
              yAxisID: qc.axis,
            } as IChartJSDataset
            datasets[dsKey] = ds
            dslist.push(ds)
            dsOrder++
          } else {
            ds = datasets[dsKey]
          }
          ds.data.push({ x: sample.ts * 1000, y: val })
        }
      }
    }
    generateDatasetsLabels(dslist, labelMetdata)
    colorManager.SetLineColors(dslist, colorManager)
    const data = {
      labels: buildTimestampList(timestamps),
      datasets: dslist,
      y1AxisTitle: y1AxisTitle,
      y2AxisTitle: y2AxisTitle
    } as IChartJSData
    return data
  }, [apiResults, borderDash, units, metricNames, customerAccess, deviceLookup, queryConfigsLkp])

  const timeScaleConfig = useMemo((): TimeScaleConfig => {
    if (!chartData || !chartData.labels || chartData.labels.length === 0) {
      return defaultTimeScaleConfig()
    }
    return getTimeScaleConfig({
      timeSizeSecs: (chartData.labels[chartData.labels.length-1] - chartData.labels[0]) / 1000,
      usingMonthlyBuckets: chartHasMonthlyBuckets
    })
  }, [chartData, chartHasMonthlyBuckets])

  const chartOpts = useMemo(() => {
    const out = { ...BaseChartOptions() } as IChartOptions
    let hasY1 = false
    let hasY2 = false
    for (const qc of queryConfigs) {
      hasY1 = hasY1 || qc.axis === 'y' || qc.axis === undefined
      hasY2 = hasY2 || qc.axis === 'y2'
    }
    out.scales.y.display = hasY1
    out.scales.y2.display = hasY2
    out.plugins.title.text = chartDisplayConfig.title || ''

    if (timeScaleConfig.labelFormat) {
      out.scales.x.time.displayFormats = {}
      out.scales.x.time.displayFormats[timeScaleConfig.unit] = timeScaleConfig.labelFormat
    }
    out.scales.x.time.unit = timeScaleConfig.unit
    out.scales.x.ticks.maxTicksLimit = timeScaleConfig.maxTicks

    if (chartDisplayConfig) {
      if (hasY1) {
        if (chartDisplayConfig.y1Max !== undefined) {
          out.scales.y.max = chartDisplayConfig.y1Max
        }
        if (chartDisplayConfig.y1Min !== undefined) {
          out.scales.y.min = chartDisplayConfig.y1Min
        }
      }
      if (hasY2) {
        if (chartDisplayConfig.y2Max !== undefined) {
          out.scales.y2.max = chartDisplayConfig.y2Max
        }
        if (chartDisplayConfig.y2Min !== undefined) {
          out.scales.y2.min = chartDisplayConfig.y2Min
        }
      }
    }
    return out
  }, [queryConfigs, chartDisplayConfig, timeScaleConfig])

  const getGroundTruthDevices = useCallback(async (cfg: IQueryConfig) => {
    const lkp = {} as siteDevices
    await api.getGroundTruthDevices(cfg.site)
    .then((result) => {
      if (!result || result.length === 0) {
        return
      }
      for (const device of result) {
        lkp[device.id] = device
      }
      setDeviceLookup(lkp)
    })
  }, [setDeviceLookup])

  const getGroundTruthHistoricalData = useCallback(async (cfg: IQueryConfig, results: APIResultLookup) => {
    await api.getGroundTruthHistoricalData(cfg, timeRange)
    .then((result: APIResult) => {
      result.id = cfg.id
      result.source = 'Ground-truth'
      results[cfg.id] = result
    })
  }, [timeRange])

  const getWeatherHistoricalData = useCallback(async (cfg: IQueryConfig, results: APIResultLookup) => {
    await api.getWeatherHistoricalData(cfg, timeRange)
    .then((result) => {
      result.id = cfg.id
      result.source = 'Weather Service (Historical)'
      result.queryConfig = cfg
      results[cfg.id] = result
    })
  }, [timeRange])

  const getSyntheticHistoricalData = useCallback(async (cfg: IQueryConfig, results: APIResultLookup) => {
    await api.getSyntheticHistoricalData(cfg, timeRange)
    .then((result) => {
      result.id = cfg.id
      result.source = 'Nanoclimate Synthetic'
      result.queryConfig = cfg
      results[cfg.id] = result
    })
  }, [timeRange])

  const getForecastNanoclimate = useCallback(async (cfg: IQueryConfig, results: APIResultLookup) => {
    await api.getForecastNanoclimate(cfg, timeRange)
    .then((result) => {
      result.id = cfg.id
      result.source = 'Nanoclimate Forecast'
      result.queryConfig = cfg
      results[cfg.id] = result
    })

  }, [timeRange])

  const getForecastWeather = useCallback(async (cfg: IQueryConfig, results: APIResultLookup) => {
    await api.getForecastWeather(cfg, timeRange)
    .then((result) => {
      result.id = cfg.id
      result.source = 'Weather Forecast'
      result.queryConfig = cfg
      results[cfg.id] = result
    })
  }, [timeRange])

  const fetch = useCallback(async (cfg: IQueryConfig, results: APIResultLookup,
    isQuiet: boolean = false) => {
    switch (cfg.source) {
      case 'gt-historical':
        if (!isQuiet) {
          await getGroundTruthDevices(cfg)
        }
        await getGroundTruthHistoricalData(cfg, results)
        break
      case 'weather-historical':
        await getWeatherHistoricalData(cfg, results)
        break
      case 'synthetic':
        await getSyntheticHistoricalData(cfg, results)
        break
      case 'nanoclimate-forecast':
        await getForecastNanoclimate(cfg, results)
        break
      case 'weather-forecast':
        await getForecastWeather(cfg, results)
        break
    }
  }, [getGroundTruthDevices, getGroundTruthHistoricalData, getWeatherHistoricalData,
      getSyntheticHistoricalData, getForecastNanoclimate, getForecastWeather])

  const fetchAll = useCallback(async (isQuiet: boolean) => {
    if (!areAllQueryConfigsValid) {
      return
    }
    const results = {} as APIResultLookup
    if (!isQuiet) {
      setIsLoading(true)
    }
    const loadCompletions = [] as boolean[]
    const errors = [] as any
    for (const cfg of queryConfigs) {
      fetch(cfg, results, isQuiet)
      .catch((e) => {
        console.log("error fetching query", e)
        errors.push(e)
      })
      .finally(() => {
        loadCompletions.push(true)
        if (loadCompletions.length < queryConfigs.length) {
          return
        }
        setApiResults(results)
        if (!isQuiet) {
          setIsLoading(false)
        }
      })
    }
  }, [queryConfigs, setIsLoading, areAllQueryConfigsValid, fetch])

  // this queries data from the API
  useEffect(() => {
    fetchAll(false)
  }, [queryConfigs, setIsLoading, areAllQueryConfigsValid, fetchAll])

  // this silently reloads / queries data from the API
  useEffect(() => {
    if (quietReloadNeeded) {
      fetchAll(true)
      .finally(() => {
        setQuietReloadNeeded(false)
    })
    }
  }, [quietReloadNeeded, setQuietReloadNeeded, fetchAll])

  return (
    <>
      <ChartView
        className="chart-view-builder"
        chartData={chartData}
        chartOptions={chartOpts}
        displayConfig={chartDisplayConfig}
        timeRange={timeRange}
        watermarkLocation={chartHasBars ? 'top.left' : 'auto'}
        isLoading={isLoading}
        containerSource={props.containerSource}
        queryConfigs={queryConfigs}
        requestReload={setQuietReloadNeeded}
        libraryChart={props.libraryChart}
        libraryChartLoader={props.libraryChartLoader}
      />
    </>
  )
}
