import { useContext, useEffect, useMemo, useState } from "react"
import { PrefsContext } from "../../prefs/PrefsContext"
import { IQueryConfig, DefaultQueryConfigs, IChartDisplayConfig, DefaultChartDisplayConfig } from "./IQueryConfig"
import { TimeRanges } from "../../time/timeRanges"
import { newUUID } from "../../uuid"

import * as storage from './Storage'
import { getCustomerFromHash, getDisplayConfigFromHash, getQueryConfigsFromHash, getTimeRangeFromHash, updateHash } from "./hash"

import BuilderPanel from "./BuilderPanel"
import ChartDataManager from "./ChartDataManager"

import './Builder.css'
import { useNavigate } from "react-router-dom"

interface BuilderProps {
  className?: string
}

const loadQueryConfigs = (customer: string): IQueryConfig[] => {
  let cfgs = getQueryConfigsFromHash()
  if (cfgs.length > 0) {
    return cfgs
  }
  return storage.loadBuilderQueryConfigs(customer)
}

const loadDisplayConfig = (customer: string): IChartDisplayConfig => {
  const cfgs = getQueryConfigsFromHash()
  if (cfgs.length === 0) {
    return storage.loadBuilderChartDisplayConfig(customer)
  }
  return getDisplayConfigFromHash() || DefaultChartDisplayConfig()
}

const loadTimeRange = (): string => {
  const tr = getTimeRangeFromHash()
  if (tr) {
    return tr
  }
  return storage.loadTimeRange()
}

export default function Builder(props: BuilderProps) {

  const { customer, setCustomerFunc, hasCustomer } = useContext(PrefsContext)
  const { className } = props
  const nav = useNavigate()

  const cust = getCustomerFromHash() || customer

  useEffect(() => {
  if (cust && cust !== customer) {
    setCustomerFunc(cust)
  }
  }, [cust, customer, hasCustomer, setCustomerFunc, nav])

  const hasAccess = useMemo(() => {
    return hasCustomer(cust)
  }, [cust, hasCustomer])

  // this can be used in dev to wipe stored data to test clean slates:
  // storage.deleteCustomerChartStorage(customer)

  const [queryConfigs, setQueryConfigs] = useState(loadQueryConfigs(cust))
  const [chartDisplayConfig, setChartDisplayConfig] = useState(loadDisplayConfig(cust))
  const [timeRange, setTimeRange] = useState(loadTimeRange)

  updateHash(cust, timeRange, queryConfigs, chartDisplayConfig)

  const [builderKey, setBuilderKey] = useState(newUUID())
  const [managerKey, setManagerKey] = useState(newUUID())

  useEffect(() => {
    if (!customer || !hasAccess) {
      return
    }
    setQueryConfigs(loadQueryConfigs(customer))
    setChartDisplayConfig(loadDisplayConfig(customer))
    setBuilderKey(newUUID())
    setManagerKey(newUUID())
  }, [customer, setQueryConfigs, setChartDisplayConfig, hasAccess])

  const resetBuilderConfigs = () => {
    const qcfg = DefaultQueryConfigs()
    const id = newUUID()
    qcfg[0].id = id
    onQueryConfigsChange(qcfg)
    onChartDisplayConfigChange(DefaultChartDisplayConfig())
    setManagerKey(newUUID())
  }

  const onQueryConfigsChange = (data: IQueryConfig[]) => {
    setQueryConfigs(data)
    storage.saveBuilderQueryConfigs(customer, JSON.stringify(data))
    updateHash(customer, timeRange, data, chartDisplayConfig)
  }

  const onChartDisplayConfigChange = (data: IChartDisplayConfig) => {
    setChartDisplayConfig(data)
    storage.saveBuilderChartDisplayConfig(customer, JSON.stringify(data))
    updateHash(customer, timeRange, queryConfigs, data)
  }

  const onTimeRangeChange = (t: TimeRanges) => {
    if (t.startVal === 'now') {
      t.startSign = ''
    } else if (t.startMode === 'now') {
      t.startVal = 'now'
      t.startSign = ''
    }
    if (t.endVal === 'now' || t.endMode === 'now') {
      t.endSign = ''
      t.endVal = ''
    }
    const tr = `${t.startSign}${t.startVal}-${t.endSign}${t.endVal}`
    storage.saveTimeRange(tr)
    setTimeRange(tr)
    updateHash(customer, tr, queryConfigs, chartDisplayConfig)
  }

  const classes = useMemo(() => {
    const base = 'chart-builder'
    if (className) {
      return `${base} ${className}`
    }
    return base
  }, [className])

  if (!hasAccess) {
    return <></>
  }

  return (
    <div className={classes} key={'chart-builder-' + customer}>
        <BuilderPanel
          queryConfigs={queryConfigs}
          chartDisplayConfig={chartDisplayConfig}
          timeRange={timeRange}
          onQueryConfigsChange={onQueryConfigsChange}
          onChartDisplayConfigChange={onChartDisplayConfigChange}
          onTimeRangeChange={onTimeRangeChange}
          key={builderKey}
          resetFunc={resetBuilderConfigs}
        />
        <ChartDataManager
          key={managerKey}
          containerSource="builder"
          chartDisplayConfig={chartDisplayConfig}
          queryConfigs={queryConfigs}
          timeRange={timeRange}
        />
    </div>
  )
}
